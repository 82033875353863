import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";

import { useTranslation } from "react-i18next";
import {
  ECABIN,
  findValueInText,
  formatClientInfoString,
  formatTimeWithTZOffset,
  getToken,
  invoiceIssuers,
  paymentStates,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import EditOrder from "./EditOrder";
import NewOrder from "./NewOrder";

const server = EnvSettings.server;

export default function Orders(props) {
  const classes = useStyles();
  const [searchList, setSearchList] = useState();
  const [orderList, setOrderList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [filterStates, setFilterStates] = useState([
    "invoiced",
    "deposit",
    "completed",
    "transit",
  ]);
  const [selectedOrder, setSelectedOrder] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order_id = props?.computedMatch?.params?.order_id;

  const editBoxRef = useRef(null);

  const executeScroll = () => {
    editBoxRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const showOrder = (dev) => {
    setSelectedOrder();
    // Hack for refreshing the selected device component
    setTimeout(() => {
      setSelectedOrder(dev);
      if (editBoxRef && editBoxRef.current) {
        executeScroll();
      }
    }, 1);
  };

  const getOrders = (ser_val) => {
    setLoading(true);
    setSearchList();
    setSelectedOrder();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: searchValue || ser_val,
      }),
    };
    fetch(server + "/get_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setOrderList(result.orders);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(getOrders, []);

  const filterResults = () => {
    setSearchList(
      orderList &&
        orderList.filter((contact) => {
          return (
            filterStates.find((o) => o === contact.paymentStatus) &&
            (findValueInText(contact.number, searchValue) ||
              findValueInText(contact.clientInfo, searchValue) ||
              findValueInText(contact.genericDevices, searchValue))
          );
        })
    );
  };

  useEffect(filterResults, [searchValue, orderList, filterStates]);

  useEffect(() => {
    if (order_id) {
      setFilterStates(paymentStates.map((m) => m.value));
      setSearchValue(order_id);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <NewOrder />
      <Grid item xs={12}>
        <Paper className={classes.paper} style={{ overflow: "hidden" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item container spacing={3} xs={12}>
              <Grid item xs={12}>
                <Title>{t("orders.header")}</Title>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoFocus
                label={t("search")}
                type="text"
                fullWidth
                variant="outlined"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value.toLowerCase());
                }}
                ref={editBoxRef}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <EditOrder selectedOrder={selectedOrder} />
          {searchList ? (
            <>
              <Grid container item xs={12}>
                <Grid item xs={11}>
                  <Typography>{t("orders.paymentStatus")}</Typography>
                  {paymentStates.map((o) => (
                    <FormControlLabel
                      key={o.label}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e, v) => {
                            if (v) {
                              setFilterStates([...filterStates, o.value]);
                              return;
                            }
                            setFilterStates([
                              ...filterStates.filter((f) => f !== o.value),
                            ]);
                          }}
                          defaultChecked={
                            !!filterStates.find((f) => f === o.value)
                          }
                        />
                      }
                      label={t("paymentStates." + o.value)}
                    />
                  ))}
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ textAlign: "center" }}>
                    Backlog Total{" "}
                    {orderList.reduce(
                      (partialSum, a) =>
                        a.paymentStatus === "invoiced" ||
                        a.paymentStatus === "deposit"
                          ? partialSum + a.totalPrice - a.depositAmount
                          : partialSum,
                      0
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <SortableTable
                headCells={[
                  {
                    id: "createdAt",
                    numeric: false,
                    disablePadding: false,
                    label: t("createdAt"),
                  },
                  {
                    id: "User.fullName",
                    numeric: false,
                    disablePadding: false,
                    label: t("createdBy"),
                  },
                  {
                    id: "invoiceIssuer",
                    numeric: false,
                    disablePadding: false,
                    label: t("orders.invoiceIssuer"),
                  },
                  {
                    id: "number",
                    numeric: false,
                    disablePadding: false,
                    label: t("orders.orderNumber"),
                  },
                  {
                    id: "paymentStatus",
                    numeric: false,
                    disablePadding: false,
                    label: t("orders.paymentStatus"),
                  },
                  {
                    id: "clientInfo",
                    numeric: false,
                    disablePadding: false,
                    label: t("clientInfo"),
                  },
                  {
                    id: "genericDevices",
                    numeric: false,
                    disablePadding: false,
                    label: t("items"),
                  },
                  {
                    id: "depositAmount",
                    numeric: false,
                    disablePadding: false,
                    label: "Backlog",
                  },
                ]}
                // disablePagination: true,
                defaultSortRow={"createdAt"}
                handleRowClick={showOrder}
                getRowStyle={(data) => {
                  var obj = { cursor: "pointer" };
                  if (data.paymentStatus === "deposit") {
                    return { ...obj, backgroundColor: "#ffbf00" };
                  }
                  if (data.paymentStatus === "completed") {
                    return { ...obj, backgroundColor: "#ccffcc" };
                  }
                  if (data.paymentStatus === "transit") {
                    return { ...obj, backgroundColor: "#00ff00" };
                  }
                  if (data.paymentStatus === "delivered") {
                    return { ...obj, backgroundColor: "limegreen" };
                  }
                  if (data.paymentStatus === "cancelled") {
                    return { ...obj, backgroundColor: "lightgrey" };
                  }
                  return obj;
                }}
                specialDataFormatting={[
                  {
                    id: "createdAt",
                    format: (data) => {
                      return formatTimeWithTZOffset(data, ECABIN);
                    },
                  },
                  {
                    id: "clientInfo",
                    format: (data) => {
                      return formatClientInfoString(data, [
                        "companyName",
                        "contactName",
                        "location",
                      ]);
                    },
                  },
                  {
                    id: "genericDevices",
                    format: (data) => {
                      var x;
                      if (searchValue && searchValue !== "") {
                        x = data.filter(
                          (f) =>
                            f.name.toLowerCase().includes(searchValue) ||
                            f.sku.toLowerCase().includes(searchValue)
                        );
                        if (x.length) {
                          return (
                            <>
                              {x.map((v) => (
                                <Typography
                                  style={{
                                    backgroundColor: "yellow",
                                    fontSize: 15,
                                  }}
                                >
                                  {v.count + " x " + v.name}
                                </Typography>
                              ))}
                            </>
                          );
                        }
                      }
                      if (data && data.length > 1) {
                        x = 0;
                        data.forEach((d) => (x = x + d.count));
                        return t("count") + ":" + x;
                      }
                      var a = "";
                      data.forEach((v) => (a = v.count + " x " + v.name));
                      return a;
                    },
                  },
                  {
                    id: "paymentStatus",
                    format: (data, e, v) => {
                      return (
                        <ListItem>
                          {t(
                            "paymentStates." +
                              paymentStates.find((o) => o.value === data).value
                          )}
                          {data === "transit" && v.multipleShipments ? (
                            <Tooltip
                              title={"This Order has multiple shipments"}
                              placement="top"
                            >
                              <ListItemIcon size="small">
                                <InfoIcon
                                  style={{ verticalAlign: "middle" }}
                                  fontSize="small"
                                />
                              </ListItemIcon>
                            </Tooltip>
                          ) : null}
                        </ListItem>
                      );
                    },
                  },
                  {
                    id: "invoiceIssuer",
                    format: (data) => {
                      if (!data) {
                        return "";
                      }
                      return Object.entries(invoiceIssuers).find(
                        ([k, o], v) => {
                          if (k === data) return o;
                          return undefined;
                        }
                      )[1].name;
                    },
                  },
                  {
                    id: "depositAmount",
                    format: (data, d, row) => {
                      if (
                        !(
                          row.paymentStatus === "invoiced" ||
                          row.paymentStatus === "deposit"
                        )
                      ) {
                        return "";
                      }
                      return row.totalPrice - row.depositAmount;
                    },
                  },
                ]}
                // handleScrollClick: showDeviceNewTab,
                tableData={searchList}
                rowsPerPage={50}
              />
            </>
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}
