import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import GetApp from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SortableTable from "../../Components/SortableTable";
import StockItemSearch from "../../Components/StockItems/StockItemSearch";
import Title from "../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
  warehouseLocation,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import EditStockItemDialog from "./EditStockItemDialog";

const server = EnvSettings.server;

async function search(stock, searchValue) {
  var array = [];
  if (searchValue.includes(",")) {
    var servallist = searchValue.split(",");
    servallist = servallist.filter((ser) => ser.trim() !== "");
    array = stock.filter((s) => {
      return servallist.some(
        (ser) =>
          s.StockItem.name.toLowerCase().includes(ser.toLowerCase()) ||
          s.StockItem.sku.toLowerCase().includes(ser.toLowerCase()) ||
          s.Warehouse.name.toLowerCase().includes(ser.toLowerCase()) ||
          s.StockItem.StockItemGroups.map((ss) =>
            ss.StockItemGroupName.name.toLowerCase()
          )
            .join(" ")
            .includes(ser.toLowerCase())
      );
    });
    return array;
  }
  array = stock.filter((s) => {
    return (
      s.StockItem.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      s.StockItem.sku.toLowerCase().includes(searchValue.toLowerCase()) ||
      s.Warehouse.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      s.StockItem.StockItemGroups.map((ss) =>
        ss.StockItemGroupName.name.toLowerCase()
      )
        .join(" ")
        .includes(searchValue.toLowerCase())
    );
  });
  return array;
}

const alertFilter = async (stock) => {
  var array = stock.filter((s) => {
    return (s.reserved && s.reserved >= s.free) || s.free < s.alertQuantity;
  });
  return array;
};

export default function StockModule(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchList, setSearchList] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [useAlertFilter, setUseAlertFilter] = useState(false);
  const [reservationsAdded, setReservationsAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState();
  const [resupplyOrderList, setResupplyOrderList] = useState();
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState();
  const [stock, setStock] = useState();
  const dispatch = useDispatch();

  const headCells = [
    {
      id: "StockItem.sku",
      numeric: false,
      disablePadding: false,
      label: t("stockModule.sku"),
    },
    {
      id: "StockItem.name",
      numeric: false,
      disablePadding: false,
      label: t("stockModule.name"),
    },
    {
      id: "StockItem.StockItemGroups",
      numeric: false,
      disablePadding: false,
      label: "Tags",
    },
    {
      id: "reserved",
      numeric: false,
      disablePadding: false,
      label: t("stockModule.reservations"),
    },
    {
      id: "free",
      numeric: false,
      disablePadding: false,
      label: t("stockModule.free"),
    },
    {
      id: "total",
      numeric: false,
      disablePadding: false,
      label: t("stockModule.total"),
    },
    {
      id: "alertQuantity",
      numeric: false,
      disablePadding: false,
      label: t("stockModule.alertQuantity"),
    },
    {
      id: "incoming",
      numeric: false,
      disablePadding: false,
      label: t("stockModule.incoming"),
    },
    {
      id: "Warehouse.name",
      numeric: false,
      disablePadding: false,
      label: t("stockModule.warehouse"),
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: t("updatedAt"),
    },
  ];

  const addStockQuantity = (data) => {
    if (selectedItem) {
      data = selectedItem;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        stock_item: data,
      }),
    };
    fetch(server + "/add_stock_quantity", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
        setLoading(false);
      });
  };

  const getOrders = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
      }),
    };
    fetch(server + "/get_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setOrderList(result.orders);
        }
      })
      .catch((e) => {});
  };

  const getResupplyOrders = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
      }),
    };
    fetch(server + "/get_resupply_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setResupplyOrderList(result.orders);
        }
      })
      .catch((e) => {});
  };

  const getStockQuantities = (ser_val) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        includePacking: true,
      }),
    };
    fetch(server + "/get_stock_quantity", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setStock(result.stock);
          // setSearchList(result.stock);
        }
        setLoading(false);
      });
  };

  const editRow = (data) => {
    // if (data.Warehouse.name !== "vantaa") {
    //   dispatch(
    //     showPopupSnackbar({
    //       message: "You can edit only the values in Vantaa",
    //       error: true,
    //     })
    //   );
    //   return;
    // }
    setEdit(data);
  };

  useEffect(() => {
    getOrders();
    getResupplyOrders();
  }, []);

  useEffect(() => {
    if (!searchList) {
      getStockQuantities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStockQuantities]);

  function downloadJSONAsCSV(jsonData) {
    // Fetch JSON data from the endpoint
    // Convert JSON data to CSV
    if (!jsonData.length) {
      alert("No records");
      return;
    }
    let csvData = jsonToCsv(jsonData); // Add .items.data
    // Create a CSV file and allow the user to download it
    let blob = new Blob([csvData], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download =
      "stock_report_" + new Date().toISOString().substring(0, 10) + ".csv";
    document.body.appendChild(a);
    a.click();
  }

  function jsonToCsv(jsonData) {
    let csv = "";
    // Get the headers
    headCells.forEach((h, idx) => {
      csv += h.label + (idx === headCells.length - 1 ? "" : ";");
    });
    csv += "\n";
    jsonData.forEach((a) => {
      headCells.forEach((h, idx) => {
        var d = "";
        try {
          var x = h.id.split(".");
          x.forEach((xx, i) => {
            if (!i) {
              d = a[xx];
            } else {
              d = d[xx];
              if (xx === "StockItemGroups") {
                d = d.map((f) => f.StockItemGroupName.name).join(",");
              }
            }
          });
        } catch (error) {}
        csv +=
          (d === null ? "" : d) + (idx === headCells.length - 1 ? "" : ";");
      });
      csv += "\n";
    });
    return csv;
  }

  const filterResults = (val) => {
    search(stock, searchValue).then((arr) => {
      if (arr && arr.length && arr[0].reserved === undefined) {
        arr.forEach((a, i) => (arr[i].reserved = 0));
        orderList.forEach((o) => {
          if (o.genericDevices && o.genericDevices.length) {
            o.genericDevices.forEach((g) => {
              var idx = arr.findIndex((s) => s.StockItem.id === g.id);
              if (
                idx !== -1 &&
                arr[idx] &&
                (o.paymentStatus === "invoiced" ||
                  o.paymentStatus === "deposit" ||
                  o.paymentStatus === "completed")
              ) {
                arr[idx].reserved = arr[idx].reserved + g.count;
              }
            });
          }
        });
      }
      if (arr && arr.length && arr[0].incoming === undefined) {
        arr.forEach((a, i) => (arr[i].incoming = 0));
        resupplyOrderList.forEach((o) => {
          if (o.genericDevices && o.genericDevices.length) {
            o.genericDevices.forEach((g) => {
              var idx = arr.findIndex((s) => s.StockItem.id === g.id);
              if (
                idx !== -1 &&
                arr[idx] &&
                o.paymentStatus !== "delivered" &&
                o.paymentStatus !== "cancelled"
              ) {
                arr[idx].incoming = arr[idx].incoming + g.count;
              }
              if (o.Shipments && o.Shipments.length) {
                var cnt = o.Shipments.reduce(
                  (prev, b) =>
                    prev +
                    (b.status === "delivered" &&
                    b.genericDevices.find((ggg) => ggg.id === g.id)
                      ? b.genericDevices.find((ggg) => ggg.id === g.id).count
                      : 0),
                  0
                );
                if (cnt) {
                  arr[idx].incoming = arr[idx].incoming - cnt;
                }
              }
            });
          }
        });
      }

      if (useAlertFilter) {
        alertFilter(arr).then((ar) => {
          setSearchList(ar);
        });
      } else {
        setSearchList(arr);
      }
      if (val) {
        setStock(arr);
      }
    });
  };

  useEffect(() => {
    if (orderList && resupplyOrderList && stock && !reservationsAdded) {
      filterResults(true);
      setReservationsAdded(true);
    }
    // eslint-disable-next-line
  }, [orderList, resupplyOrderList, stock]);

  useEffect(() => {
    if (stock && orderList && (searchValue || searchValue === "")) {
      filterResults();
    }
    // eslint-disable-next-line
  }, [searchValue, useAlertFilter]);

  return (
    <Grid container spacing={3}>
      <EditStockItemDialog
        edit={edit}
        setEdit={setEdit}
        addStockQuantity={addStockQuantity}
      />
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("stock.header")}</Title>
          <TextField
            label={t("search")}
            placeholder={t("stockModule.searchPlaceholder")}
            variant="outlined"
            value={searchValue}
            InputLabelProps={{ shrink: !!searchValue ? true : undefined }}
            onChange={(e) => {
              if (e.target.value) {
                setSearchValue(e.target.value);
                return;
              }
              setSearchValue("");
            }}
            style={{ marginBottom: 10 }}
          />

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={useAlertFilter}
                onChange={() => {
                  setUseAlertFilter(!useAlertFilter);
                }}
              />
            }
            label={t("stockModule.onlyAlerts")}
          ></FormControlLabel>
          {add ? (
            <Grid container item xs={12} spacing={1}>
              {!selectedItem ? (
                <Grid item container xs={6}>
                  <StockItemSearch
                    onChange={(e, v) => {
                      if (v && v.sku) {
                        setSelectedItem(v);
                      }
                    }}
                  />
                </Grid>
              ) : null}
              {selectedItem ? (
                <>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={t("stockModule.sku")}
                      value={
                        selectedItem.sku !== "Add new item"
                          ? selectedItem.sku
                          : undefined
                      }
                      disabled={!!selectedItem.id}
                      fullWidth
                      onChange={(e) => {
                        setSelectedItem({
                          ...selectedItem,
                          sku: e.target.value,
                        });
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={t("stockModule.name")}
                      value={selectedItem.name}
                      disabled={!!selectedItem.id}
                      fullWidth
                      required
                      onChange={(e) => {
                        setSelectedItem({
                          ...selectedItem,
                          name: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      variant="outlined"
                      label={t("stockModule.free")}
                      fullWidth
                      type="number"
                      onChange={(e, v) => {
                        setSelectedItem({
                          ...selectedItem,
                          free: e.target.value,
                          total: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={t("stockModule.warehouse")}
                      fullWidth
                      value={"vantaa"}
                      disabled
                    />
                  </Grid>
                  {selectedItem.name ? (
                    <Grid item xs={1}>
                      <Button
                        onClick={() => {
                          addStockQuantity();
                        }}
                        variant="contained"
                        color="primary"
                      >
                        {t("submit")}
                      </Button>
                    </Grid>
                  ) : null}
                </>
              ) : null}
              <Grid item xs={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedItem();
                    setAdd(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <Button
                variant="outlined"
                onClick={() => {
                  setAdd(true);
                }}
              >
                {t("addRow")}
              </Button>
            </Grid>
          )}
          <Grid item style={{ marginTop: 5 }}>
            <Button
              variant="outlined"
              onClick={() => {
                downloadJSONAsCSV(searchList);
              }}
              endIcon={<GetApp />}
            >
              {t("download") + " .csv"}
            </Button>
          </Grid>
          {searchList ? (
            <SortableTable
              headCells={headCells}
              // disablePagination={true,
              defaultSortRow={"StockItem.sku"}
              handleRowClick={editRow}
              // handleScrollClick={showDeviceNewTab}
              tableData={searchList}
              rowsPerPage={200}
              getRowStyle={(data) => {
                var obj = { cursor: "pointer" };
                if (data.reserved && data.reserved >= data.free) {
                  return { ...obj, backgroundColor: "red" };
                }
                if (data.alertQuantity > data.free) {
                  return { ...obj, backgroundColor: "yellow" };
                }
                return obj;
              }}
              imageData={"StockItem.imageUrl"}
              // Takes the header id as identifier and
              specialDataFormatting={[
                {
                  id: "StockItem.sku",
                  format: (data, imgdata) => {
                    if (imgdata === "json") {
                      return data;
                    }
                    return (
                      <Grid container alignItems="center">
                        {imgdata ? (
                          <Grid item>
                            <img
                              alt={data + "-image"}
                              src={imgdata}
                              style={{
                                width: 50,
                                padding: 5,
                                borderRadius: 15,
                              }}
                            />
                          </Grid>
                        ) : null}
                        <Grid item>{data}</Grid>
                      </Grid>
                    );
                  },
                },
                {
                  id: "StockItem.StockItemGroups",
                  format: (data, e) => {
                    return data
                      .sort((a, b) => {
                        if (
                          a.StockItemGroupName.name > b.StockItemGroupName.name
                        ) {
                          return 1;
                        }
                        return -1;
                      })
                      .map((d, i) =>
                        e === "json" ? (
                          d.StockItemGroupName.name
                        ) : (
                          <Typography
                            key={i}
                            style={{
                              fontSize: 12,
                              textAlign: "center",
                              backgroundColor: "lightgrey",
                              padding: 3,
                              borderRadius: 10,
                              marginBottom: 1,
                              zIndex: 9999,
                            }}
                            onClick={(e) => {
                              setSearchValue(d.StockItemGroupName.name);
                              // Hack to prevent opening the edit window
                              setEdit();
                              setTimeout(() => {
                                setEdit();
                              }, 1);
                            }}
                          >
                            {d.StockItemGroupName.name}
                          </Typography>
                        )
                      );
                  },
                },
                {
                  id: "Warehouse.name",
                  format: (d) => {
                    return (
                      warehouseLocation.find((a) => a.value === d)?.label || ""
                    );
                  },
                },
                {
                  id: "updatedAt",
                  format: (d) => {
                    return formatTimeWithTZOffset(d, ECABIN);
                  },
                },
              ]}
            />
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}
