import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch } from "react-redux";
import {
  getToken,
  getUser,
  requestErrorHandler,
  USER_GROUPS,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";

const server = EnvSettings.server;

function ConfirmDeleteDialog({ showDialog, deleteOrder, onClose }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Device</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" autoFocus>
          {t("no")}
        </Button>
        <Button onClick={deleteOrder} color="secondary" variant="contained">
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function DeviceDataForm({ deviceData, updateLocalDevice }) {
  const [edit, setEdit] = useState(false);
  const [editedData, setEditedData] = useState(deviceData);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const submitChanges = (del_dev) => {
    if (
      !del_dev &&
      (!deviceData || !editedData || shallowEqual(editedData, deviceData))
    ) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceData: editedData,
        ...(del_dev ? del_dev : {}),
      }),
    };
    fetch(server + "/edit_device", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setEdit(false);
          if (del_dev) {
            window.location.reload();
          }
          if (updateLocalDevice) {
            updateLocalDevice(editedData);
          }
        }
      });
  };

  return editedData ? (
    <Grid container spacing={3}>
      <ConfirmDeleteDialog
        onClose={() => {
          setShowDelete(false);
        }}
        showDialog={showDelete}
        deleteOrder={() => {
          submitChanges({ deleteDevice: true });
        }}
      />
      {Object.entries(deviceData).map((a, b) =>
        a[0] === "id" ? null : [
            "serialNumber",
            "LifeCycleStates",
            "billNumber",
            "currentLCLocation",
            "currentLCState",
            "latestTimestamp",
            "clientInfo",
            "note",
            "user",
            "companyName",
          ].findIndex((c) => c === a[0]) !== -1 ? null : [
            "plugType",
            "color",
            "chassisNumber",
          ].findIndex((c) => c === a[0]) !== -1 ? (
          <Grid item xs={12} sm={3} key={b}>
            <TextField
              fullWidth
              label={t("deviceList." + a[0])}
              defaultValue={a[1]}
              variant="outlined"
              color="primary"
              disabled={!edit}
              focused={edit}
              onChange={(e) => {
                var obj;
                if (e.target.value && e.target.value !== "") {
                  obj = { ...editedData, [a[0]]: e.target.value };
                } else {
                  obj = { ...editedData, [a[0]]: null };
                }
                setEditedData(obj);
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={3} key={b}>
            <TextField
              fullWidth
              label={t("deviceList." + a[0])}
              defaultValue={a[1]}
              variant="outlined"
              disabled
            />
          </Grid>
        )
      )}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => {
            if (edit) {
              setEditedData({ ...deviceData });
            }
            setEdit(!edit);
          }}
        >
          {edit ? t("cancel") : t("edit")}
        </Button>
        {!edit || shallowEqual(editedData, deviceData) ? null : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              submitChanges();
            }}
          >
            {t("save")}
          </Button>
        )}
        {edit && getUser().permissionlevel === USER_GROUPS.SUPER_USER ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowDelete(true);
            }}
          >
            {t("delete")}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  ) : null;
}
