import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";

import { useTranslation } from "react-i18next";
import {
  ECABIN,
  findValueInText,
  formatClientInfoString,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
  shipmentStatus,
  warehouseLocation,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import EditShipment from "./EditShipment";
import NewShipment from "./NewShipment";

const server = EnvSettings.server;

export default function Shipments(props) {
  const classes = useStyles();
  const history = useHistory();
  const [searchList, setSearchList] = useState();
  const [shipmentsList, setShipmentsList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [showForm, setShowForm] = useState(false);
  const [filterStates, setFilterStates] = useState(["waiting", "transit"]);
  const [selectedShipment, setSelectedShipment] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shipment_id = props?.computedMatch?.params?.shipment_id;
  const order_id = props?.computedMatch?.params?.order_id;
  const resupply_order_id = props?.computedMatch?.params?.resupply_order_id;

  const editBoxRef = useRef(null);

  const executeScroll = () => {
    editBoxRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const showShipment = (dev) => {
    setSelectedShipment();
    // Hack for refreshing the selected device component
    setTimeout(() => {
      setSelectedShipment(dev);
      if (editBoxRef && editBoxRef.current) {
        executeScroll();
      }
    }, 1);
  };

  const getShipments = (ser_val) => {
    setLoading(true);
    setSearchList();
    setSelectedShipment();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: searchValue || ser_val,
      }),
    };
    fetch(server + "/get_shipments", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setShipmentsList(result.shipments);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // useEffect(getOrders, []);

  const filterResults = () => {
    setSearchList(
      shipmentsList &&
        shipmentsList.filter((contact) => {
          return (
            filterStates.find((o) => o === contact.status) &&
            (findValueInText(contact.number, searchValue) ||
              findValueInText(contact.genericDevices, searchValue) ||
              findValueInText(contact.Order, searchValue) ||
              findValueInText(contact.ResupplyOrder, searchValue) ||
              findValueInText(contact.shippingInfo, searchValue))
          );
        })
    );
  };

  useEffect(filterResults, [searchValue, shipmentsList, filterStates]);

  useEffect(getShipments, []);

  useEffect(() => {
    if (shipment_id) {
      setFilterStates(shipmentStatus.map((m) => m.value));
      setSearchValue(shipment_id);
    }
    if (order_id) {
      setShowForm(true);
    }
    if (resupply_order_id) {
      setShowForm(true);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {!showForm ? (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowForm(!showForm);
                }}
              >
                {t("shipments.addNewShipment")} +
              </Button>
            </Grid>
          ) : null}
        </Paper>
      </Grid>
      <NewShipment
        open={showForm}
        handleExit={() => {
          setShowForm(false);
          if (order_id || resupply_order_id) {
            history.push("/cpanel/shipments");
            window.location.reload();
          }
        }}
        orderId={order_id}
        resupplyOrderId={resupply_order_id}
      />
      <Grid item xs={12}>
        <Paper className={classes.paper} style={{ overflow: "hidden" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item container spacing={3} xs={12}>
              <Grid item xs={12}>
                <Title>{t("shipments.header")}</Title>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoFocus
                label={t("search")}
                type="text"
                fullWidth
                variant="outlined"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value.toLowerCase());
                }}
                ref={editBoxRef}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={() => {
                  // getOrders();
                }}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <EditShipment selectedShipment={selectedShipment} />
          {searchList ? (
            <>
              <Grid container item xs={12}>
                <Grid item xs={11}>
                  <Typography>{t("shipments.status")}</Typography>
                  {shipmentStatus.map((o, i) => (
                    <FormControlLabel
                      key={o.value}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e, v) => {
                            if (v) {
                              setFilterStates([...filterStates, o.value]);
                              return;
                            }
                            setFilterStates([
                              ...filterStates.filter((f) => f !== o.value),
                            ]);
                          }}
                          defaultChecked={
                            !!filterStates.find((f) => f === o.value)
                          }
                        />
                      }
                      label={t("shipmentStates." + o.value)}
                    />
                  ))}
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
              <SortableTable
                headCells={[
                  {
                    id: "createdAt",
                    numeric: false,
                    disablePadding: false,
                    label: t("createdAt"),
                  },
                  {
                    id: "number",
                    numeric: false,
                    disablePadding: false,
                    label: t("shipments.number"),
                  },
                  {
                    id: "externalRef",
                    numeric: false,
                    disablePadding: false,
                    label: t("shipments.externalRef"),
                  },
                  {
                    id: "User.fullName",
                    numeric: false,
                    disablePadding: false,
                    label: t("createdBy"),
                  },

                  {
                    id: "status",
                    numeric: false,
                    disablePadding: false,
                    label: t("shipments.status"),
                  },

                  {
                    id: "shippingInfo",
                    numeric: false,
                    disablePadding: false,
                    label: t("orders.shippingInfo"),
                  },
                  {
                    id: "genericDevices",
                    numeric: false,
                    disablePadding: false,
                    label: t("items"),
                  },
                  {
                    id: "Order",
                    numeric: false,
                    disablePadding: false,
                    label: t("shipments.partOfOrder"),
                  },
                ]}
                // disablePagination: true,
                defaultSortRow={"createdAt"}
                handleRowClick={showShipment}
                getRowStyle={(data) => {
                  var obj = { cursor: "pointer" };
                  if (data.status === "transit") {
                    return { ...obj, backgroundColor: "#ccffcc" };
                  }
                  if (data.status === "delivered") {
                    return { ...obj, backgroundColor: "limegreen" };
                  }
                  if (data.status === "cancelled") {
                    return { ...obj, backgroundColor: "lightgrey" };
                  }
                  if (data.status === "returned") {
                    return { ...obj, backgroundColor: "red" };
                  }
                  return obj;
                }}
                specialDataFormatting={[
                  {
                    id: "createdAt",
                    format: (data) => {
                      return formatTimeWithTZOffset(data, ECABIN);
                    },
                  },
                  {
                    id: "genericDevices",
                    format: (data) => {
                      var x;
                      if (searchValue && searchValue !== "") {
                        x = data.filter(
                          (f) =>
                            f.name?.toLowerCase().includes(searchValue) ||
                            f.sku?.toLowerCase().includes(searchValue)
                        );
                        if (x.length) {
                          return (
                            <>
                              {x.map((v, i) => (
                                <Typography
                                  key={i}
                                  style={{
                                    backgroundColor: "yellow",
                                    fontSize: 15,
                                  }}
                                >
                                  {v.count + " x " + v.name}
                                </Typography>
                              ))}
                            </>
                          );
                        }
                      }
                      if (data && data.length > 1) {
                        x = 0;
                        data.forEach((d) => (x = x + d.count));
                        return t("count") + ":" + x;
                      }
                      var a = "";
                      data.forEach((v) => (a = v.count + " x " + v.name));
                      return a;
                    },
                  },
                  {
                    id: "status",
                    format: (data) => {
                      return t(
                        "shipmentStates." +
                          shipmentStatus.find((o) => o.value === data).value
                      );
                    },
                  },
                  {
                    id: "shippingInfo",
                    format: (data, d, dd) => {
                      if (!dd.Order && !dd.ResupplyOrder) {
                        if (dd.shipmentDirection === "outward") {
                          return formatClientInfoString(data, [
                            "companyName",
                            "contactName",
                            "location",
                          ]);
                        } else if (
                          dd.shipmentDirection === "inward" ||
                          dd.shipmentDirection === "between"
                        ) {
                          return warehouseLocation.find(
                            (f) =>
                              formatClientInfoString(data, ["companyName"]) ===
                              f.value
                          )?.label;
                        }
                        return "";
                      }
                      return dd.Order
                        ? formatClientInfoString(data, [
                            "companyName",
                            "contactName",
                            "location",
                          ])
                        : dd.ResupplyOrder
                        ? warehouseLocation.find(
                            (f) =>
                              formatClientInfoString(data, ["companyName"]) ===
                              f.value
                          ).label
                        : "";
                    },
                  },
                  {
                    id: "Order",
                    format: (data, d, dd) => {
                      if (dd.Order) {
                        return "Order " + dd.Order.number;
                      } else if (dd.ResupplyOrder) {
                        return "Resupply " + dd.ResupplyOrder.number;
                      }
                      return "";
                    },
                  },
                ]}
                // handleScrollClick: showDeviceNewTab,
                tableData={searchList}
                rowsPerPage={50}
              />
            </>
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}
