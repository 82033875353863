import { MenuItem, Paper, Typography, withWidth } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../../Components/Title";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
import {
  getToken,
  requestErrorHandler,
  shipmentStatus,
  shippingMethods,
  warehouseLocation,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import ShippingItemTable from "../ShippingItemTable";
// import ShippingItemLine from "./ShippingItemLine";
/**
 * Delivery to
 * Items
 * External Ref
 * Order
 * Term
 * Shipping Company
 * Status
 * Pickup
 * ETA
 * Tracking number
 */

const server = EnvSettings.server;

const getFields = (data, key) => {
  if (!key || !data) {
    return;
  }
  var arr;
  if (key.includes(".")) {
    arr = key.split(".");
  }
  if (!arr) {
    arr = [key];
  }
  var value;
  arr.forEach((element, i) => {
    if (i === 0) {
      value = Object.keys(data).find((a) => a === element) ? data[element] : "";
    } else {
      value = Object.keys(value).find((a) => a === element)
        ? value[element]
        : "";
    }
    if (value && Array.isArray(value) && value.length) {
      value = value.sort((a, b) => b.id - a.id)[0];
    }
  });
  if (key.includes("quality") && value) {
    return "Class: " + value;
  }
  return value || "";
};

function ResupplyOrderSearchAutocomplete({
  onChange,
  userGroup,
  required,
  defaultValue,
  label,
  email,
  style,
  additionalDataFields,
  additionalFilters,
}) {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const { t } = useTranslation();

  const getUsers = (ser_val) => {
    if (!ser_val) {
      return;
    }
    setLoadingUsersList(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        // search_value: ser_val,
      }),
    };
    fetch(server + "/get_resupply_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setUsersList(result.orders);
        }
        setLoadingUsersList(false);
      });
  };
  return (
    <Grid item xs={12} style={style || {}}>
      <Autocomplete
        id="orders"
        options={[...usersList].filter(
          (f) =>
            f &&
            f.paymentStatus !== "delivered" &&
            (!additionalFilters ||
              !additionalFilters.find((a) => a === f.paymentStatus))
        )}
        fullWidth
        getOptionSelected={(option, value) => {
          return option.number === value.number;
        }}
        PaperComponent={(props) => (
          <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
        )}
        getOptionLabel={(option) => {
          if (!option || !option.number) {
            return "";
          }
          return option.number;
        }}
        loading={loadingUsersList}
        defaultValue={{ number: defaultValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || t("orders.order")}
            variant="outlined"
            required={required}
          />
        )}
        renderOption={(option, { selected }) => (
          <React.Fragment key={option.sku}>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
                  {option.number}
                </Typography>
              </Grid>
              {additionalDataFields
                ? additionalDataFields.map((m, i) => (
                    <Grid key={i} item xs={12}>
                      <Typography style={{ fontSize: 12 }}>
                        {getFields(option, m)}
                      </Typography>
                    </Grid>
                  ))
                : null}
            </Grid>
          </React.Fragment>
        )}
        onChange={onChange}
        onChangeCapture={(e) => {
          var v;
          if (e.target.value) {
            v = e.target.value.toLowerCase();
          }
          getUsers(v);
          // getClientInfo(v);
        }}
      />
    </Grid>
  );
}

function OrderShipment({
  width,
  shipmentData,
  setShipmentData,
  resupplyOrderId,
}) {
  const [order, setOrder] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (order && !shipmentData) {
      var obj = {
        ...shipmentData,
        ResupplyOrder: order,
        genericDevices: order.genericDevices,
        status: "waiting",
        shippingInfo: { companyName: "mailhouse" },
      };
      setShipmentData(JSON.parse(JSON.stringify(obj)));
    }
  }, [order]);

  const getOrder = (ser_val) => {
    if (!ser_val) {
      return;
    }
    // setLoadingUsersList(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: ser_val,
      }),
    };
    fetch(server + "/get_resupply_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.orders && result.orders.length === 1) {
            setOrder(result.orders[0]);
          }
          // setOrder(result.orders);
        }
        // setLoadingUsersList(false);
      });
  };

  useEffect(() => {
    if (resupplyOrderId) {
      getOrder(resupplyOrderId);
    }
    return () => {
      setOrder();
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        {!order ? (
          <ResupplyOrderSearchAutocomplete
            additionalDataFields={[
              "invoiceIssuer",
              "totalPrice",
              "currency",
              "genericDevices.sku",
            ]}
            additionalFilters={["transit"]}
            onChange={(e, v) => {
              if (v) {
                setOrder(v);
              }
            }}
          />
        ) : (
          <Title>
            {t("shipments.shipmentForResupplyOrder")} {order.number}
          </Title>
        )}
      </Grid>
      {shipmentData ? (
        <>
          <Grid item xs={6} sm={4}>
            <Typography>{t("shipments.invoiceIssuer")}:</Typography>
            <Typography>{order?.invoiceIssuer}</Typography>
          </Grid>
          <Grid
            container
            direction="column"
            item
            sm={4}
            justify="space-between"
          >
            {["shippingCompany", "externalRef", "shippingTerm"].map((m, i) => (
              <TextField
                key={m + i}
                label={t("shipments." + m)}
                variant="outlined"
                fullWidth
                onChange={(e, v) => {
                  if (!e || !e.target) {
                    return;
                  }
                  var obj = { ...shipmentData, [m]: e.target.value };
                  setShipmentData({ ...obj });
                }}
              />
            ))}
            <TextField
              label={t("shipments.deliveryTo")}
              defaultValue={"mailhouse"}
              variant="outlined"
              onChange={(e, v) => {
                if (!e || !e.target.value) {
                  return;
                }
                var obj = {
                  ...shipmentData,
                  shippingInfo: {
                    ...shipmentData.shippingInfo,
                    companyName: e.target.value,
                  },
                };
                setShipmentData({ ...obj });
              }}
              fullWidth
              select
            >
              {warehouseLocation.map((m, i) => (
                <MenuItem key={m.value + i} value={m.value}>
                  {m.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={t("shipments.shippingMethod")}
              variant="outlined"
              fullWidth
              select
              onChange={(e, v) => {
                if (!e || !e.target.value) {
                  return;
                }
                var obj = { ...shipmentData, shippingMethod: e.target.value };
                setShipmentData({ ...obj });
              }}
            >
              {shippingMethods.map((s, i) => (
                <MenuItem value={s.value} key={i}>
                  {t("shippingMethods." + s.value)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            container
            direction="column"
            item
            sm={4}
            justify="space-between"
            style={{ minHeight: 325 }}
          >
            {["pickupDate", "eta"].map((m, i) => (
              <TextField
                key={m + i}
                label={t("shipments." + m)}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type={"date"}
                onChange={(e, v) => {
                  if (!e || !e.target) {
                    return;
                  }
                  var obj = { ...shipmentData, [m]: e.target.value };
                  setShipmentData({ ...obj });
                }}
              />
            ))}
            <TextField
              label={t("shipments.trackingNumber")}
              variant="outlined"
              fullWidth
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...shipmentData, trackingNumber: e.target.value };
                setShipmentData({ ...obj });
              }}
            />
            <TextField
              label={t("shipments.status")}
              variant="outlined"
              fullWidth
              defaultValue={"waiting"}
              select
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...shipmentData, status: e.target.value };
                setShipmentData({ ...obj });
              }}
            >
              {shipmentStatus.map((p, i) => (
                <MenuItem value={p.value} key={i}>
                  {t("shipmentStates." + p.value)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={t("shipments.totalPrice")}
              type="number"
              value={shipmentData.totalPrice}
              variant="outlined"
              fullWidth
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...shipmentData, totalPrice: e.target.value };
                setShipmentData({ ...obj });
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={width === "xs" ? "" : 3}>
            {order.genericDevices.length ? (
              <ShippingItemTable
                listOfItems={order.genericDevices}
                shipmentData={shipmentData}
                setShipmentData={setShipmentData}
                width={width}
              />
            ) : null}
            <Grid item xs={12}>
              <TextField
                fullWidth
                rows={2}
                multiline
                label={t("details")}
                variant="outlined"
                onChange={(e, v) => {
                  if (!e || !e.target) {
                    return;
                  }
                  var obj = { ...shipmentData, details: e.target.value };
                  setShipmentData({ ...obj });
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
}

export default withWidth()(OrderShipment);
