import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";

import { useTranslation } from "react-i18next";
import {
  ECABIN,
  findValueInText,
  formatTimeWithTZOffset,
  getToken,
  paymentStates,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import EditOrder from "./EditOrder";
import NewOrder from "./NewOrder";

const server = EnvSettings.server;

export default function ResupplyOrders(props) {
  const classes = useStyles();
  const [searchList, setSearchList] = useState();
  const [orderList, setOrderList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [filterStates, setFilterStates] = useState([
    "invoiced",
    "deposit",
    "completed",
    "transit",
  ]);
  const [selectedOrder, setSelectedOrder] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order_id = props?.computedMatch?.params?.order_id;

  const editBoxRef = useRef(null);

  const executeScroll = () => {
    editBoxRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const showOrder = (dev) => {
    setSelectedOrder();
    // Hack for refreshing the selected device component
    setTimeout(() => {
      setSelectedOrder(dev);
      if (editBoxRef && editBoxRef.current) {
        executeScroll();
      }
    }, 1);
  };

  const getResupplyOrders = (ser_val) => {
    setLoading(true);
    setSearchList();
    setSelectedOrder();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: searchValue || ser_val,
      }),
    };
    fetch(server + "/get_resupply_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setOrderList(result.orders);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // useEffect(getOrders, []);

  const filterResults = () => {
    setSearchList(
      orderList &&
        orderList.filter((contact) => {
          return (
            filterStates.find((o) => o === contact.paymentStatus) &&
            (findValueInText(contact.number, searchValue) ||
              findValueInText(contact.genericDevices, searchValue))
          );
        })
    );
  };

  useEffect(filterResults, [searchValue, orderList, filterStates]);

  useEffect(getResupplyOrders, []);

  useEffect(() => {
    if (order_id) {
      setFilterStates(paymentStates.map((m) => m.value));
      setSearchValue(order_id);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <NewOrder />
      <Grid item xs={12}>
        <Paper className={classes.paper} style={{ overflow: "hidden" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item container spacing={3} xs={12}>
              <Grid item xs={12}>
                <Title>{t("resupplyOrders.header")}</Title>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                autoFocus
                label={t("search")}
                type="text"
                fullWidth
                variant="outlined"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value.toLowerCase());
                }}
                ref={editBoxRef}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={() => {
                  // getOrders();
                }}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <EditOrder selectedOrder={selectedOrder} />
          {searchList ? (
            <>
              <Grid container item xs={12}>
                <Grid item xs={11}>
                  <Typography>{t("orders.paymentStatus")}</Typography>
                  {paymentStates.map((o) => (
                    <FormControlLabel
                      key={o.label}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e, v) => {
                            if (v) {
                              setFilterStates([...filterStates, o.value]);
                              return;
                            }
                            setFilterStates([
                              ...filterStates.filter((f) => f !== o.value),
                            ]);
                          }}
                          defaultChecked={
                            !!filterStates.find((f) => f === o.value)
                          }
                        />
                      }
                      label={t("paymentStates." + o.value)}
                    />
                  ))}
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
              <SortableTable
                headCells={[
                  {
                    id: "createdAt",
                    numeric: false,
                    disablePadding: false,
                    label: t("createdAt"),
                  },
                  {
                    id: "eta",
                    numeric: false,
                    disablePadding: false,
                    label: "ETA",
                  },
                  {
                    id: "User.fullName",
                    numeric: false,
                    disablePadding: false,
                    label: t("createdBy"),
                  },
                  {
                    id: "invoiceIssuer",
                    numeric: false,
                    disablePadding: false,
                    label: t("orders.invoiceIssuer"),
                  },
                  {
                    id: "number",
                    numeric: false,
                    disablePadding: false,
                    label: t("orders.orderNumber"),
                  },
                  {
                    id: "paymentStatus",
                    numeric: false,
                    disablePadding: false,
                    label: t("orders.paymentStatus"),
                  },
                  {
                    id: "genericDevices",
                    numeric: false,
                    disablePadding: false,
                    label: t("items"),
                  },
                  {
                    id: "totalPrice",
                    numeric: false,
                    disablePadding: false,
                    label: t("orders.totalPrice"),
                  },
                ]}
                // disablePagination: true,
                defaultSortRow={"createdAt"}
                handleRowClick={showOrder}
                getRowStyle={(data) => {
                  var obj = { cursor: "pointer" };
                  if (data.paymentStatus === "deposit") {
                    return { ...obj, backgroundColor: "#ffbf00" };
                  }
                  if (data.paymentStatus === "completed") {
                    return { ...obj, backgroundColor: "#ccffcc" };
                  }
                  if (data.paymentStatus === "transit") {
                    return { ...obj, backgroundColor: "#00ff00" };
                  }
                  if (data.paymentStatus === "delivered") {
                    return { ...obj, backgroundColor: "limegreen" };
                  }
                  if (data.paymentStatus === "cancelled") {
                    return { ...obj, backgroundColor: "lightgrey" };
                  }
                  return obj;
                }}
                specialDataFormatting={[
                  {
                    id: "createdAt",
                    format: (data) => {
                      return formatTimeWithTZOffset(data, ECABIN);
                    },
                  },
                  {
                    id: "genericDevices",
                    format: (data) => {
                      if (data && data.length > 1) {
                        var x = 0;
                        data.forEach((d) => (x = x + d.count));
                        return t("count") + ":" + x;
                      }
                      var a = "";
                      data.forEach((v) => (a = v.count + " x " + v.name));
                      return a;
                    },
                  },
                  {
                    id: "paymentStatus",
                    format: (data) => {
                      return t(
                        "paymentStates." +
                          paymentStates.find((o) => o.value === data).value
                      );
                    },
                  },
                ]}
                // handleScrollClick: showDeviceNewTab,
                tableData={searchList}
                rowsPerPage={50}
              />
            </>
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}
