import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  ListItem,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  withWidth,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Description from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DialogAppBar from "../../../Components/DialogAppBar";
import NoteSection from "../../../Components/NoteSection";
import Title from "../../../Components/Title";
import {
  getToken,
  requestErrorHandler,
  shipmentStatus,
  shippingMethods,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";
import { showPopupSnackbar } from "../../../redux/actions/snackbarActions";
import CreatePackingList from "../CreatePackingList";
import ShippingAddress from "../ShippingAddress";
import ShippingItemTable from "../ShippingItemTable";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
const server = EnvSettings.server;

function MediaFiles({ ticket, listCallback }) {
  const classes = useStyles();
  const [checklistURL, setChecklistURL] = useState();
  // const [pdfURL, setPdfURL] = useState();
  const [files, setFiles] = useState();
  const [fileToDelete, setFileToDelete] = useState();
  const { t } = useTranslation();

  const fileUploadRef = useRef();

  const dispatch = useDispatch();

  const getPhotos = () => {
    setChecklistURL();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        shipment: ticket,
      }),
    };
    fetch(server + "/get_shipment_files", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.files) {
            setChecklistURL(result);
            if (listCallback) {
              listCallback(result);
            }
          }
        }
      });
  };

  useEffect(() => {
    if (!ticket || !ticket.number) {
      return;
    }
    getPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket, dispatch]);

  const getContentType = (val, blob) => {
    if (val && val.includes(".pdf")) {
      return new Blob([blob], { type: "application/pdf" });
    }
    var arr = val.split(".");
    if (arr.length) {
      return new Blob([blob], { type: "image/" + arr[arr.length - 1] });
    }
  };

  const uploadPhoto = (e) => {
    if (!files && !!files.length) {
      return;
    }
    const data = new FormData();
    data.append("token", getToken());
    files.forEach((element) => {
      data.append("files[]", element);
    });
    data.append(
      "resource",
      JSON.stringify({ type: "shipment", number: ticket?.number })
    );
    fetch(server + "/upload_shipment_files", {
      method: "POST",
      // headers: {
      //   "content-type": file.type,
      //   "content-length": `${file.size}`, // 👈 Headers need to be a string
      // },
      body: data,
    })
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        dispatch(showPopupSnackbar(result));
        if (!result.error) {
          getPhotos();
          fileUploadRef.current.value = null;
        }
      });
  };

  const removePhoto = () => {
    if (!fileToDelete && !ticket?.number) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        filename: fileToDelete,
        resource: { type: "shipment", number: ticket.number },
      }),
    };
    fetch(server + "/delete_shipment_file", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
        }

        getPhotos();
      });
  };

  const showPhoto = (val, dir) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        filename: val,
        file_location: dir,
      }),
    };
    fetch(server + "/show_checklist", requestOptions)
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => {
        // setPdfURL(blob);
        const fileName = "checklist.pdf";

        const newBlob = getContentType(val, blob);

        const newWindow = window.open("", fileName, "width=800,height=1200");
        if (newWindow) {
          setTimeout(() => {
            const dataUrl = window.URL.createObjectURL(newBlob);
            const title = newWindow.document.createElement("title");
            const iframe = newWindow.document.createElement("iframe");

            title.appendChild(document.createTextNode(fileName));
            newWindow.document.head.appendChild(title);

            iframe.setAttribute("width", "99%");
            iframe.setAttribute("height", "99%");

            iframe.setAttribute("src", dataUrl);

            newWindow.document.body.appendChild(iframe);

            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              // if (!val.includes(".pdf")) {
              //   newWindow.document.body
              //     .getElementsByTagName("iframe")[0]
              //     .contentDocument.getElementsByTagName("img")[0]
              //     .setAttribute("style", "height:-webkit-fill-available;");
              // }
              window.URL.revokeObjectURL(dataUrl);
            }, 100);
          }, 100);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleClose = () => {
    setFileToDelete(undefined);
  };

  return ticket && ticket.number ? (
    <Grid container spacing={3}>
      <Dialog
        open={fileToDelete ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("delete")} {fileToDelete}?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              removePhoto();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3} item xs={12} sm={6}>
        <Grid item xs={12}>
          <Title>{t("media")}</Title>
        </Grid>
        {checklistURL &&
        checklistURL.files.filter((f) => !f.includes("packing_list")).length ? (
          checklistURL.files.map((f, i) => (
            <Grid key={i} item xs={12}>
              <Button
                variant="outlined"
                onClick={() => showPhoto(f, checklistURL.root_dir)}
              >
                {f}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setFileToDelete(f);
                }}
              >
                {t("delete")}
              </Button>
            </Grid>
          ))
        ) : (
          <Grid item>
            <Typography>{t("noMediaAvailable")}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} sm={6}>
        <Grid item xs={12}>
          <Title>{t("uploadNewMedia")}</Title>
        </Grid>
        <Grid item>
          <input
            accept="image/*,.pdf"
            className={classes.input}
            id="contained-button-file"
            type="file"
            multiple
            onChange={(e) => {
              if (e.target.files) {
                setFiles(Array.prototype.slice.call(e.target.files));
              }
            }}
            ref={fileUploadRef}
          />
        </Grid>
        <Grid item>
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              disabled={!(files && files.length) ? true : false}
              onClick={uploadPhoto}
            >
              {t("uploadMedia")}
            </Button>
          </label>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Typography>{t("ticketList.submitTicketFirst")}!</Typography>
  );
}

function EditShipment({ selectedShipment, width }) {
  const [editable, setEditable] = useState(false);
  const [editedShipment, setEditedShipment] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showPackingList, setShowPackingList] = useState(false);
  const [loadingPackingList, setLoadingPackingList] = useState(false);
  const [packingListExist, setPackingListExist] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getContentType = (val, blob) => {
    return new Blob([blob], { type: "application/pdf" });
  };

  const showInvoice = (val, callback, check, download) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        filename: val + ".pdf",
        file_location: "./shipments/" + selectedShipment.number,
      }),
    };
    fetch(server + "/show_checklist", requestOptions)
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => {
        return new Response(stream);
      })
      // Create an object URL for the response
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        // setPdfURL(blob);
        if (callback && (check || blob.size < 150)) {
          return callback(blob.size > 150);
        }
        if (download) {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", val + ".pdf");

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          return;
        }

        const fileName = "checklist.pdf";

        const newBlob = getContentType(val, blob);

        const newWindow = window.open("", fileName, "width=800,height=1200");
        if (newWindow) {
          setTimeout(() => {
            const dataUrl = window.URL.createObjectURL(newBlob);
            const title = newWindow.document.createElement("title");
            const iframe = newWindow.document.createElement("iframe");

            title.appendChild(document.createTextNode(fileName));
            newWindow.document.head.appendChild(title);

            iframe.setAttribute("width", "99%");
            iframe.setAttribute("height", "99%");

            iframe.setAttribute("src", dataUrl);

            newWindow.document.body.appendChild(iframe);

            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(dataUrl);
            }, 100);
          }, 100);
        }
      })
      .catch((err) => {
        console.error(err);
        if (callback) {
          callback();
        }
      });
  };

  const deleteShipment = () => {
    if (!editedShipment) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        shipment: editedShipment,
      }),
    };
    fetch(server + "/delete_shipment", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  const editShipment = () => {
    if (!editedShipment) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        shipment: editedShipment,
      }),
    };
    fetch(server + "/edit_shipment", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    if (selectedShipment) {
      setEditedShipment(JSON.parse(JSON.stringify(selectedShipment)));
      showInvoice(
        "packing_list_" + selectedShipment.number,
        (v) => {
          setLoadingPackingList(false);
          setPackingListExist(!!v);
        },
        true
      );
    }
    // eslint-disable-next-line
  }, [selectedShipment, setEditedShipment]);

  const handleExit = () => {
    setEditable(false);
    setEditedShipment();
  };

  return editedShipment ? (
    <Dialog open={!!editedShipment} fullScreen onClose={handleExit}>
      <DialogContent>
        <DialogAppBar header={t("orders.orderDetails")} onClose={handleExit} />
        <Grid
          container
          spacing={1}
          style={{
            marginTop: 60,
            marginBottom: 20,
            paddingBottom: 30,
            position: "relative",
          }}
        >
          <CreatePackingList
            showPackingList={showPackingList}
            setShowPackingList={setShowPackingList}
            callback={() => {
              setPackingListExist(true);
            }}
            // setPackingListCancelled={setPackingListCancelled}
          />
          <ConfirmDeleteDialog
            onClose={() => {
              setShowDialog(false);
            }}
            showDialog={showDialog}
            deleteOrder={deleteShipment}
          />
          <Grid item xs={6} sm={4}>
            <Title>Shipment ID: {editedShipment.number}</Title>
            {editedShipment?.Order ? (
              <Typography>
                {t("shipments.shipmentForOrder")}:{" "}
                <Link href={"/cpanel/orders/" + editedShipment.Order.number}>
                  {editedShipment.Order.number}
                </Link>
              </Typography>
            ) : editedShipment?.ResupplyOrder ? (
              <Typography>
                {t("shipments.shipmentForResupplyOrder")}:{" "}
                <Link
                  href={
                    "/cpanel/resupply/" + editedShipment.ResupplyOrder.number
                  }
                >
                  {editedShipment.ResupplyOrder.number}
                </Link>
              </Typography>
            ) : (
              <Title>
                {t("shipments." + editedShipment.shipmentDirection)}{" "}
                {t("shipments.shipment")}
              </Title>
            )}
            {editedShipment.Order ? (
              <ShippingAddress
                editable={editable}
                shipmentData={editedShipment}
                setShipmentData={setEditedShipment}
              />
            ) : editedShipment.ResupplyOrder ? (
              <Grid item xs={6} sm={4}>
                <Typography>{t("shipments.invoiceIssuer")}:</Typography>
                <Typography>
                  {editedShipment.ResupplyOrder.invoiceIssuer}
                </Typography>
              </Grid>
            ) : editedShipment.shipmentDirection === "outward" ? (
              <ShippingAddress
                editable={true}
                setShipmentData={setEditedShipment}
                shipmentData={editedShipment}
              />
            ) : editedShipment.shipmentDirection === "inward" ? (
              <ShippingAddress
                editable={true}
                setShipmentData={() => {}}
                shipmentData={editedShipment}
                header={t("shipments.pickupAddress")}
                customSetter={(e, v, name) => {
                  setEditedShipment({
                    ...editedShipment,
                    shippingInfo: {
                      ...editedShipment.shippingInfo,
                      pickupAddress: {
                        ...editedShipment.shippingInfo.pickupAddress,
                        [name]: e.target.value,
                      },
                    },
                  });
                }}
              />
            ) : (
              <>
                <TextField
                  label={t("shipments.pickupAddress")}
                  variant="outlined"
                  disabled={!editable}
                  value={editedShipment.pickupAddress}
                  onChange={(e, v) => {
                    if (!e || !e.target.value) {
                      return;
                    }
                    var obj = {
                      ...editedShipment,
                      pickupAddress: e.target.value,
                    };
                    setEditedShipment({ ...obj });
                  }}
                  fullWidth
                  select
                >
                  <MenuItem value={"estonia"}>Estonia</MenuItem>
                  <MenuItem value={"vantaa"}>Vantaa</MenuItem>
                  <MenuItem value={"mailhouse"}>Mailhouse</MenuItem>
                  <MenuItem value={"reimax"}>Reimax</MenuItem>
                </TextField>
                <TextField
                  style={{ marginTop: 10 }}
                  label={t("shipments.deliveryTo")}
                  disabled={!editable}
                  value={editedShipment.shippingInfo.companyName}
                  variant="outlined"
                  onChange={(e, v) => {
                    if (!e || !e.target.value) {
                      return;
                    }
                    var obj = {
                      ...editedShipment,
                      shippingInfo: { companyName: e.target.value },
                    };
                    setEditedShipment({ ...obj });
                  }}
                  fullWidth
                  select
                >
                  <MenuItem value={"estonia"}>Estonia</MenuItem>
                  <MenuItem value={"vantaa"}>Vantaa</MenuItem>
                  <MenuItem value={"mailhouse"}>Mailhouse</MenuItem>
                  <MenuItem value={"reimax"}>Reimax</MenuItem>
                </TextField>
              </>
            )}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              {editable &&
              !selectedShipment.ResupplyOrderOrder &&
              !packingListExist ? (
                <Button
                  disabled={loadingPackingList}
                  onClick={() => {
                    setShowPackingList(selectedShipment);
                  }}
                  startIcon={<Description />}
                  variant="outlined"
                >
                  {t("create") + " " + t("packingList")}
                </Button>
              ) : !selectedShipment.ResupplyOrder && packingListExist ? (
                <>
                  <ListItem>
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("packingList")}
                    </Typography>
                    <Tooltip title={t("download")}>
                      <IconButton
                        onClick={() => {
                          showInvoice(
                            "packing_list_" + selectedShipment.number,
                            undefined,
                            undefined,
                            true
                          );
                        }}
                      >
                        <SaveAltIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("edit")}>
                      <IconButton
                        onClick={() => {
                          setShowPackingList(selectedShipment);
                        }}
                        disabled={!editable}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                </>
              ) : loadingPackingList ? (
                <CircularProgress
                  size={30}
                  style={{ padding: 5, verticalAlign: "center" }}
                />
              ) : !selectedShipment.ResupplyOrder ? (
                <Typography style={{ fontWeight: "bold" }}>
                  {t("shipments.packingListNotCreated")}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            item
            sm={4}
            justify="space-between"
          >
            <TextField
              disabled={!editable}
              label={t("shipments.shippingCompany")}
              variant="outlined"
              fullWidth
              value={editedShipment.shippingCompany}
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = {
                  ...editedShipment,
                  shippingCompany: e.target.value,
                };
                setEditedShipment({ ...obj });
              }}
            />
            <TextField
              disabled={!editable}
              label={t("shipments.externalRef")}
              variant="outlined"
              fullWidth
              value={editedShipment.externalRef}
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = {
                  ...editedShipment,
                  externalRef: e.target.value,
                };
                setEditedShipment({ ...obj });
              }}
            />
            <TextField
              disabled={!editable}
              label={t("shipments.shippingTerm")}
              variant="outlined"
              fullWidth
              value={editedShipment.shippingTerm}
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = {
                  ...editedShipment,
                  shippingTerm: e.target.value,
                };
                setEditedShipment({ ...obj });
              }}
            />
            {editedShipment.Order || editedShipment.ResupplyOrder ? (
              <TextField
                disabled={!editable}
                label={
                  editedShipment.Order
                    ? t("shipments.pickupAddress")
                    : t("shipments.deliveryTo")
                }
                defaultValue={"mailhouse"}
                variant="outlined"
                value={
                  editedShipment.Order
                    ? editedShipment.pickupAddress
                    : editedShipment.shippingInfo?.companyName
                }
                onChange={(e, v) => {
                  if (!e || !e.target.value) {
                    return;
                  }
                  var obj;
                  if (editedShipment.Order) {
                    obj = {
                      ...editedShipment,
                      pickupAddress: e.target.value,
                    };
                  } else {
                    obj = {
                      ...editedShipment,
                      shippingInfo: { companyName: e.target.value },
                    };
                  }
                  setEditedShipment({ ...obj });
                }}
                fullWidth
                select
              >
                <MenuItem value={"estonia"}>Estonia</MenuItem>
                <MenuItem value={"vantaa"}>Vantaa</MenuItem>
                <MenuItem value={"mailhouse"}>Mailhouse</MenuItem>
                <MenuItem value={"reimax"}>Reimax</MenuItem>
              </TextField>
            ) : editedShipment.shipmentDirection === "outward" ? (
              <TextField
                disabled={!editable}
                label={t("shipments.pickupAddress")}
                defaultValue={"mailhouse"}
                variant="outlined"
                onChange={(e, v) => {
                  if (!e || !e.target.value) {
                    return;
                  }
                  var obj = {
                    ...editedShipment,
                    pickupAddress: e.target.value,
                  };
                  setEditedShipment({ ...obj });
                }}
                fullWidth
                select
              >
                <MenuItem value={"estonia"}>Estonia</MenuItem>
                <MenuItem value={"vantaa"}>Vantaa</MenuItem>
                <MenuItem value={"mailhouse"}>Mailhouse</MenuItem>
                <MenuItem value={"reimax"}>Reimax</MenuItem>
              </TextField>
            ) : editedShipment.shipmentDirection === "inward" ? (
              <TextField
                disabled={!editable}
                label={t("shipments.deliveryTo")}
                defaultValue={"mailhouse"}
                variant="outlined"
                onChange={(e, v) => {
                  if (!e || !e.target.value) {
                    return;
                  }
                  var obj = {
                    ...editedShipment,
                    shippingInfo: {
                      ...editedShipment.shippingInfo,
                      companyName: e.target.value,
                    },
                  };
                  setEditedShipment({ ...obj });
                }}
                fullWidth
                select
              >
                <MenuItem value={"estonia"}>Estonia</MenuItem>
                <MenuItem value={"vantaa"}>Vantaa</MenuItem>
                <MenuItem value={"mailhouse"}>Mailhouse</MenuItem>
                <MenuItem value={"reimax"}>Reimax</MenuItem>
              </TextField>
            ) : null}
            <TextField
              disabled={!editable}
              label={t("shipments.shippingMethod")}
              variant="outlined"
              fullWidth
              select
              value={editedShipment.shippingMethod}
              onChange={(e, v) => {
                if (!e || !e.target.value) {
                  return;
                }
                var obj = {
                  ...editedShipment,
                  shippingMethod: e.target.value,
                };
                setEditedShipment({ ...obj });
              }}
            >
              {shippingMethods.map((s, i) => (
                <MenuItem value={s.value} key={i}>
                  {t("shippingMethods." + s.value)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            container
            direction="column"
            item
            sm={4}
            justify="space-between"
            style={{ minHeight: 325 }}
          >
            <TextField
              disabled={!editable}
              label={t("shipments.pickupDate")}
              variant="outlined"
              type="date"
              value={editedShipment.pickupDate}
              InputLabelProps={{ shrink: true }}
              fullWidth
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...editedShipment, pickupDate: e.target.value };
                setEditedShipment({ ...obj });
              }}
            />
            <TextField
              disabled={!editable}
              label={t("shipments.eta")}
              type="date"
              value={editedShipment.eta}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...editedShipment, eta: e.target.value };
                setEditedShipment({ ...obj });
              }}
            />
            <TextField
              disabled={!editable}
              label={t("shipments.trackingNumber")}
              variant="outlined"
              fullWidth
              value={editedShipment.trackingNumber}
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = {
                  ...editedShipment,
                  trackingNumber: e.target.value,
                };
                setEditedShipment({ ...obj });
              }}
            />
            <TextField
              disabled={!editable}
              label={t("shipments.status")}
              variant="outlined"
              fullWidth
              value={editedShipment.status}
              select
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...editedShipment, status: e.target.value };
                setEditedShipment({ ...obj });
              }}
            >
              {shipmentStatus.map((p, i) => (
                <MenuItem value={p.value} key={i}>
                  {t("shipmentStates." + p.value)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              disabled={!editable}
              label={t("shipments.totalPrice")}
              type="number"
              value={editedShipment.totalPrice}
              variant="outlined"
              fullWidth
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...editedShipment, totalPrice: e.target.value };
                setEditedShipment({ ...obj });
              }}
            />
          </Grid>
          <Grid item container xs={12} style={{ marginTop: 20 }}>
            <MediaFiles
              ticket={editedShipment}
              listCallback={(data) => {
                if (
                  data &&
                  data.files &&
                  data.files.find((f) => f.includes("packing_list"))
                ) {
                  setPackingListExist(true);
                }
              }}
            />
          </Grid>
          <Grid
            item
            container
            style={{ marginTop: 20 }}
            xs={12}
            spacing={width === "xs" ? "" : 3}
          >
            {editedShipment.genericDevices.length ? (
              <ShippingItemTable
                listOfItems={editedShipment.genericDevices}
                shipmentData={editedShipment}
                setShipmentData={setEditedShipment}
                width={width}
                onlyPrint={true}
              />
            ) : null}
            <Grid item xs={12}>
              <TextField
                disabled={!editable}
                fullWidth
                rows={2}
                multiline
                value={editedShipment.details}
                label={t("details")}
                variant="outlined"
                onChange={(e, v) => {
                  if (!e || !e.target) {
                    return;
                  }
                  var obj = { ...editedShipment, details: e.target.value };
                  setEditedShipment({ ...obj });
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => {
                setEditable(!editable);
              }}
            >
              {!editable ? t("edit") : t("cancel")}
            </Button>
            {editable ? (
              <>
                <Button
                  variant="contained"
                  disabled={
                    JSON.stringify(selectedShipment) ===
                    JSON.stringify(editedShipment)
                  }
                  color="primary"
                  onClick={editShipment}
                >
                  {t("save")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setShowDialog(true);
                  }}
                >
                  {t("delete")}
                </Button>
              </>
            ) : null}
          </Grid>
          <NoteSection
            parent={{ ShipmentId: editedShipment.id }}
            notes={editedShipment.Notes}
            reload={() => {
              window.location.reload();
            }}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}

export default withWidth()(EditShipment);
